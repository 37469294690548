<template>
  <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
    <!-- 其他收入(工会经费) -->
    <el-tab-pane label="其他收入(工会经费)" name="1">
      <div class="right_box" v-if="!this.paramCheck.comId">
        <el-button size="small" @click="printingNew()" type="success">打印</el-button>
        <el-button type="primary" @click="download()" size="small" plain>导出</el-button>
      </div>
      <div class="big_box4">
        <div>
          <el-radio-group v-model="expStatus">
            <el-radio :label="true">使用公式</el-radio>
            <el-radio :label="false">不使用公式</el-radio>
          </el-radio-group>
        </div>
        <div class="top_title3">
          <h4>其他收入(工会经费)</h4>
          <h5>一般纳税人、小规模纳税人适用</h5>
          <div class="bottom_font clearfix">
            <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
            <h6 class="center"><span></span></h6>
            <!-- 税款所属期：{{durationOfTaxTime}} -->
            <h6 class="right">
              <p>单位：元</p>
              <p>纳税人识别号：<span>{{taxNo}}</span></p>
            </h6>
          </div>
        </div>
        <table class="content" cellspacing="0" width="100%">
          <div class="add" @click="handleAddItem(list)">
            <i class="iconfont icon-jiahao"></i>
          </div>
          <tr class="top_bg center">
            <td style="width:6%">征收项目</td>
            <td style="width:6%">征收品目</td>
            <td style="width:6%">征收子目</td>
            <td style="width:8%">税款所属期起</td>
            <td style="width:8%;">税款所属期止</td>
            <td style="width:6%">应缴费基数</td>
            <td style="width:8%">应缴费基数减除额</td>
            <td style="width:6%">计费依据</td>
            <td style="width:6%">征收标准</td>
            <td style="width:4%">扣除数</td>
            <td style="width:4%">征收比例</td>
            <td style="width:6%">本期应纳费额</td>
            <td style="width:6%">减免费额</td>
            <td style="width:6%">减免性质</td>
            <td style="width:6%">本期已缴费额</td>
            <td style="width:8%">本期应补（退）费额</td>
          </tr>
          <tr class="top_bg center">
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
            <td>9</td>
            <td>10</td>
            <td>11</td>
            <td>12</td>
            <td>13</td>
            <td>14</td>
            <td>15</td>
            <td>16</td>
          </tr>
          <tr v-for="(item,index) in list" :key="index" class="close_item">
            <td><input type="text" v-model="item.zsxmDm"></td>
            <td><input type="text" v-model="item.zspmDm"></td>
            <td></td>
            <td>
              <el-date-picker value-format="YYYY-MM-DD" size="small" v-model="item.taxStart" type="date" placeholder="选择日期时间" style="width:100%"></el-date-picker>
            </td>
            <td>
              <el-date-picker value-format="YYYY-MM-DD" size="small" v-model="item.taxEnd" type="date" placeholder="选择日期时间" style="width:100%"></el-date-picker>
            </td>
            <qzf-str-td :disabled="false" v-model:amount="item.ybzzs" @change="changeList()"></qzf-str-td>
            <td class="center">0</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="item.hj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="item.sl1" @change="changeList()"></qzf-str-td>
            <td class="center">0</td>
            <td class="center">1</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="item.bqynsfe" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="item.jme" @change="changeList()"></qzf-str-td>
            <td class="center">
              {{item.jmxzDm}}
            </td>
            <td class="center">0</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="item.bqybtse" @change="changeList()"></qzf-str-td>
            <div class="close" @click="handleDelItem(list,index)">
              <i class="iconfont icon-shanchu"></i> 
            </div>
          </tr>
        </table>
      </div>
      <div class="save_box" v-if="$buttonStatus('swbb_bc')">
        <el-button @click="saveValues" type="primary" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
        <qzf-button @success="saveValues" type="primary" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
      </div>
    </el-tab-pane>
    <!-- 附加税 end -->
  </el-tabs>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
import { report ,exportReport} from "@/api/printSet"

export default {
  name:'taxNeimengQtsr',
  data() {
    return {
      address: "",
      durationOfTaxTime: '',
      activeName: '1',
      name: '1',
      listQuery: {},
      list: [{}],
      fjsJmList: [],
      period: '',
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      expStatus: true,
      param:{
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:['gs_qtsr']
      },
      startAccountPeriod:'',
      comId:0
    }
  },
  props: {
    paramCheck:{
      default:{},
      type:Object
    },
  },
  created() {
    if(this.paramCheck.comId){
      // //console.log("查看报表");
      this.startAccountPeriod = this.paramCheck.period
      this.comId = this.paramCheck.comId
    }
    this.getList()
  },
  methods: {
    // 公式
    changeList(){
      if(!this.expStatus){
        return
      }
      setTimeout(()=>{
        this.list.map(v=>{
          v.hj = v.ybzzs
          v.bqynsfe = (v.hj * v.sl1).toString()
          v.bqybtse =  (v.bqynsfe - v.jme).toString()
        })
      },10)
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_qtsr',
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
          }
        })
      }else{
        gsInfo({tableName: 'gs_qtsr'}).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
          }
        })
      }
    },
    saveValues(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_qtsr',
          items:this.list,
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_qtsr',
          items:this.list
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
    // 主表添加一行
    handleAddItem(item) {
      item.push({
        zsxmDm:this.list[0]?.zsxmDm,
        zspmDm:this.list[0]?.zspmDm,
        ybzzs:"",
        hj:"",
        sl1:"",
        bqynsfe:"",
        jme:"",
        jmxzDm:"",
        bqybtse:"",
        taxStart:'',
        taxEnd:'',
      });
    },
    // 主表删除一行
    handleDelItem(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },

    printingNew(){
      report(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    download(){
      exportReport(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.right_box {
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
  
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 14px !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: -28px;
    top: 9px;
    font-size: 20px;
    color: #f15a24;
    cursor: pointer;
    i{
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
      color: var(--themeColor,#17a2b8);
    }
  }
  span{
    line-height: 26px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 26px;
    // padding: 0 6px;
    font-size: 13px;
  }
}


.big_box4{
  width: 98%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}
.close_item{
  position: relative;
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -32px;
    top: 0;
    font-size: 20px;
    color: #ff1818;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
      color: var(--themeColor,#17a2b8);
    }
  }
}
</style>