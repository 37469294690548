<template>
  <div style="position: relative;">

    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick" class="top_nav">


      <!-- 水利建设专项收入 -->
      <el-tab-pane label="水利建设专项收入(非税收入)" name="1">
        <div>
          <el-radio-group v-model="expStatus">
            <el-radio :label="true">使用公式</el-radio>
            <el-radio :label="false">不使用公式</el-radio>
          </el-radio-group>
        </div>
        <div class="right_box" v-if="!this.paramCheck.comId">
          <el-button size="small" @click="printingNew()" type="success">打印</el-button>
          <el-button type="primary" @click="handleDownloadVat()" size="small" plain>导出</el-button>
        </div>

        <div class="big_box4">
          <div class="top_title3">
            <h4>水利建设专项收入(非税收入)</h4>
            <h5>一般纳税人、小规模纳税人适用</h5>
            <div class="bottom_font clearfix">
              <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
              <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
              <h6 class="right">
                <p>单位：元</p>
                <p>纳税人识别号：<span>{{taxNo}}</span></p>
              </h6>
            </div>
          </div>


          <table class="content" cellspacing="0">

            <tr class="top_bg">
              <td class="center" style="width:10%">征收项目</td>
              <td class="center" style="width:13%">征收品目</td>
              <td class="center" style="width:8%">征收子目</td>
              <!-- <td class="center">费款所属期起</td>
              <td class="center">费款所属期止</td> -->
              <td class="center" style="width:6%">应缴费基数</td>
              <td class="center" style="width:5%">应缴费基数减除额</td>
              <td class="center" style="width:6%">计费依据</td>
              <td class="center" style="width:6%">征收标准</td>
              <td class="center" style="width:4%">扣除数</td>
              <td class="center" style="width:4%">征收比例</td>
              <td class="center" style="width:6%">本期应纳费额</td>
              <td class="center" style="width:6%">减免费额</td>
              <td class="center" style="width:14%">减免性质</td>
              <td class="center" style="width:6%">本期已缴费额</td>
              <td class="center" style="width:6%">本期应补（退）费额</td>
            </tr>
           
            <tr>
              <td class="center">1</td>
              <td class="center">2</td>
              <td class="center">3</td>
              <!-- <td class="center">4</td>
              <td class="center">5</td> -->
              <td class="center">6</td>
              <td class="center">7</td>
              <td class="center">8</td>
              <td class="center">9</td>
              <td class="center">10</td>
              <td class="center">11</td>
              <td class="center">12</td>
              <td class="center">13</td>
              <td class="center">14</td>
              <td class="center">15</td>
              <td class="center">16</td>
            </tr>

            <tr>
              <td><input type="text" v-model="list[0].zsxmDm"></td>
              <td><input type="text" v-model="list[0].zspmDm"></td>
              <td></td>
 
               <!-- <td></td>
               <td></td> -->
              <qzf-str-td :disabled="false" v-model:amount="list[0].ybzzs" @change="changeList()"></qzf-str-td>
               <td>0</td>
              <qzf-str-td :disabled="expStatus" v-model:amount="list[0].hj" @change="changeList()"></qzf-str-td>
               <td>{{list[0].sl1}}</td>
               <td>0</td>
               <td>1</td>
              <qzf-str-td :disabled="expStatus" v-model:amount="list[0].bqynsfe" @change="changeList()"></qzf-str-td>
              <qzf-str-td :disabled="false" v-model:amount="list[0].jme" @change="changeList()"></qzf-str-td>
               <td>
                 {{list[0].jmxzDm}}
               </td>
               <td>0</td>
              <qzf-str-td :disabled="false" v-model:amount="list[0].bqybtse" @change="changeList()"></qzf-str-td>
            </tr>


          </table>
        </div>
      </el-tab-pane>
      <div class="save_box" v-if="$buttonStatus('swbb_bc')">
      <el-button type="primary" @click="saveValues()" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
      </div>
      <!-- 附加税 end -->
    </el-tabs>
   
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
import { report,exportReport } from "@/api/printSet"

export default {
  name: 'taxNeimengSl',
  data() {
    return {
      address: "",
      durationOfTaxTime: '',
      activeName: '1',
      name: '1',
      listQuery: {},
      list: [{},{},{},{}],
      fjsJmList: [],
      period: '',
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      expStatus:true,
      startAccountPeriod:'',
      comId:0
    }
  },
  props: {
    paramCheck:{
      default:{},
      type:Object
    },
  },
  created() {
    if(this.paramCheck.comId){
      // //console.log("查看报表");
      this.startAccountPeriod = this.paramCheck.period
      this.comId = this.paramCheck.comId
    }
    this.getList()
  },
  methods: {
    // 公式
    changeList(){
      if(!this.expStatus){
        return
      }
      this.list[0].hj = this.list[0].ybzzs
      this.list[0].bqynsfe = (this.list[0].hj * this.list[0].sl1).toString()
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_sl',
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          this.durationOfTaxTime = res.data.data.time
          this.comName = res.data.data.name
          this.taxNo = res.data.data.taxNo
          if(res.data.data.info){
            this.list = res.data.data.info
          }
        })
      }else{
        gsInfo({tableName: 'gs_sl'}).then(res=>{
          this.durationOfTaxTime = res.data.data.time
          this.comName = res.data.data.name
          this.taxNo = res.data.data.taxNo
          if(res.data.data.info){
            this.list = res.data.data.info
          }
        })
      }
    },
    saveValues(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_sl',
          items:this.list,
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_sl',
          items:this.list
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
    printingNew(){
      let param = {
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:['gs_sl']
      }
      report(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    handleDownloadVat(){
      let param = {
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:['gs_sl']
      }
      exportReport(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
  
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 13px !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 26px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 26px;
    // padding: 0 6px;
    font-size: 13px;
  }
}
.big_box4{
  width: 100%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
}

</style>